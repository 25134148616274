<template>
  <div class="Tag" :class="{ isRounded }">
    <slot></slot>

    <TagClose v-if="isRemovable" @click="$emit('onRemove')" />
  </div>
</template>

<script setup lang="ts">
type Color = "default" | "blue";

const props = withDefaults(defineProps<{ isRounded?: boolean; isRemovable?: boolean; color?: Color }>(), {
  color: "default",
});

defineEmits<{ onRemove: [] }>();

const backgroundColor = computed(() => (props.color === "default" ? `var(--gray-6)` : `var(--${props.color}-lighter)`));
const textColor = computed(() => (props.color === "default" ? `var(--gray-2)` : `var(--${props.color})`));
</script>

<style scoped lang="scss">
.Tag {
  display: flex;
  align-items: center;
  gap: 4px;
  color: v-bind(textColor);
  background-color: v-bind(backgroundColor);
  padding-inline: 4px;
  border-radius: 5px;
  height: 24px;
  overflow: hidden;

  &.isRounded {
    border-radius: 100px;
  }
}
</style>
